import React from "react";

function DocumentCard({ children }) {
  return (
    <div
      style={{
      //  backgroundColor: "yellow",
        padding: 10,
        borderColor: "#707070AA",
        borderWidth: 2,
        borderStyle: "dashed",
        borderRadius: 10,
        margin: 16,
        width: "95%",
        textAlign: "center",
        height:"100%"
      }}
    >
      {children}
    </div>
  );
}

export default DocumentCard;
