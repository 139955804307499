import React, { useState } from "react";
import styles from "./styles.module.css";


function Loader() {
 
  return (
    <div style={{justifyContent: 'center',display:'flex',flexDirection: 'column',alignContent: 'center',position:'absolute',top:'40%'}}>
    <div className={styles.loader}>
    </div>
    <br/>
      <strong>Scanning Documents</strong>
      </div>
  );
}

export default Loader;
