import React, { useState } from "react";
import axios from "axios";
import Group_1 from "../../assets/images/Group_1.png"
import FeeMonk_HighRes_Logo from "../../assets/images/FeeMonk_HighRes_Logo.png";
import whatsapp from "../../assets/images/whatsapp.png";
import "./index.css";
// import { userActions } from "../../actions/user.actions";
import { jwtDecode } from "jwt-decode";
// import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Captcha from "react-numeric-captcha";
import { TextField } from "@mui/material";

import { userService } from "./UserServices.js";


 function Index() {
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [otpSent, setOtpSent] = useState(false);

  // const dispatch = useDispatch();
  // const sendOtpState = useSelector((state) => state);
  // const applicationState = useSelector(
  //   (state) => state?.GETAPPLICATIONS?.getApplicationsData
  // );

  const [mobileNumber, setMobileNumber] = React.useState("");
  const navigate = useNavigate();
  // const location = useLocation();
  // const confirmOtpState = useSelector((state) => state);
  const [timer, setTimer] = useState(0);

  if (window?.location?.href?.split("?")?.length > 1) {
    sessionStorage
      .setItem("instituteName", window?.location?.href?.split("?")[1])
      ?.split("=");
  }

  React.useEffect(() => {
    // if (sessionStorage.getItem("token")) {
    //   navigate("/mainpage");
    // } else {
    //   navigate("/new-login");
    // }
    // sessionStorage.setItem('token',confirmOtpState?.submitotp?.submitOtp?.data?.data)
    // sessionStorage.setItem('userId', confirmOtpState?.submitotp?.submitOtp?.data?.user?._id)
    // sessionStorage.setItem('applicantId', confirmOtpState?.submitotp?.submitOtp?.data?.user?.applicantId)

    let timerInterval = setInterval(() => {
      if (timer > 0) setTimer((old) => old - 1);
      if (timer === 0) clearInterval(timerInterval);
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [timer, sessionStorage.getItem("token")]);

  const handleResendOtp = (event) => {
    setTimer(45);
    event.preventDefault();

    if (mobileNumber?.length === 10) {
      const urlGetOtp = "/login/send-otp";
      sessionStorage.setItem("mob", mobileNumber);
      const payLoad = {
        mobile: mobileNumber,
      };
      try {
        // dispatch(userActions.login(urlGetOtp, payLoad));
        userService.loginApi(urlGetOtp,payLoad)
        setOtpSent(true);
      } catch (error) {
        console.error("Error while sending OTP:", error);
        alert("Error while sending OTP!!!");
      }
      // dispatch(userActions.login(urlGetOtp, payLoad), []);
      userService.loginApi(urlGetOtp,payLoad)
      setOtpSent(true);
    } else alert("Invalid Mobile Number");

    // loggedIn(url,options)
  };

  const submitForm = async (event) => {
    event.preventDefault();

    if (mobileNumber?.length === 10) {
      const urlGetOtp = "/login/send-otp";
      sessionStorage.setItem("mob", mobileNumber);
      const payLoad = {
        mobile: mobileNumber,
      };
      try {
        // dispatch(userActions.login(urlGetOtp, payLoad));
        userService.loginApi(urlGetOtp,payLoad)
        setOtpSent(true);
      } catch (error) {
        console.error("Error while sending OTP:", error);
        alert("Error while sending OTP!!!");
      }
    } else alert("Invalid Mobile Number");

    // loggedIn(url,options)
  };


  const [enteredOtp, setEnteredOtp] = React.useState(null);

  const saveDataToLS = (data, auth_token) => {
    sessionStorage.setItem(
      "feemonk_data",
      JSON.stringify({
        user_status: data?.status,
        mob: data?.mobile,
        value: auth_token,
      })
    );
  };

  const authenticate = (auth_token) => {
    var myHeaders = new Headers();

    myHeaders.append("Authorization", `Bearer ${auth_token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/summary`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        console.log(result?.data?.status);

        // setAuthToken({
        //   ...authToken,
        //   user_status: result?.data?.status,
        // })
        saveDataToLS(result?.data, auth_token);

        if (result?.data?.status === "Admin") {
          return navigate(`/upload`);
        }

        if (
          result?.data?.status === "New" ||
          result?.data?.status === "Edited"
        ) {
          return navigate(`/admin-form`);
        }
        if (
          result?.data?.status === "Received" ||
          result?.data?.status === "CoapplicantSubmitted"
        ) {
          return navigate(`/thank-you`);
        }

        if (result?.data?.status === "Coapplicant") {
          return navigate(`/admin-form?coapp=true`);
        }

        // if (
        //   result?.data?.status === "Admin" ||
        //   result?.data?.status === "Edited"
        // ) {
        //   navigate(`/form/${mobileNumber}`);
        // } else {
        //   navigate("/form");
        // }
      })
      .catch((error) => console.log("error", error));
  };


  const submitOtp = async (event) => {
    event.preventDefault();

    if (enteredOtp?.length === 4) {
      const urlSubmitOtp = "/login/verify-otp";
      const payLoad = {
        mobile: sessionStorage.getItem("mob"),
        otp: enteredOtp,
      };

      const response =await userService.submitOtpApi(urlSubmitOtp,payLoad)
      console.log("hello")

      if (response.message === "Invalid OTP") {
        alert("Invalid OTP");
      } else if (response.data.message === "Successful") {
        console.log("hellos")
        sessionStorage.setItem("token", response?.data?.data);
       authenticate(response?.data?.data);
      }

      // dispatch(userActions.submitOtp(urlSubmitOtp, payLoad));
      // axiosInstance.post( "/user/login", payLoad)
      // .then((res)=>{
      //   if(res.status === 200){
      //     console.log(res)
      //     handleClose()
      //     navigate('/mainpage', {state : {
      //       user : res.data.user
      //     }
      //     })
      //     // setOtpReceived(true)
      //   }

      // })
      // .catch(e => {
      //   alert("Login Failed Check OTP!!!");
      // })
    } else {
      alert("Enter 4 digit OTP");
    }
  };


  



  const handleClose = () => {
    // setOpen(false);
  };
  const handleClose2 = () => {
    // setOpen(false);
    // setOpen2(true);
  };
  const onChangeMobile = (event) => {
    const mob = event.target.value;
    if (mob.length === 10) {
      setMobile(mob);
      setMobileError(mob.length !== 10 ? "Invalid Mobile" : null);
    } else {
      setMobile(mob);
      setMobileError(mob.length !== 10 ? "Invalid Mobile" : null);
    }
  };

  const onChangeOtp = (event) => {
    setOtp(event.target.value);
  };

  const [captchaSuccess, setCaptchaSuccess] = useState(false);

  // function onChange(value) {
  //   console.log("Captcha value:", value);
  // }

  console.log("this is not null")

  return (
    <>
      <div className="login-form-container">
        <div className="login-background">
          {/* <div className="login-background-2"> */}
          <img src={Group_1} className="login-image" alt="login" />
          <img
            src={FeeMonk_HighRes_Logo}
            className="feemonk-image"
            alt="website logo"
          />

          <p className="caption">
            Simplified education fee payments & fiscal management <br />
            {/* <button
            className="applybtn1"
            onClick={() => {
              navigate(
                "/eyJtb2JpbGVOdW1iZXIiOiI4NzYzMDM5MzgwIiwiY291cnNlIjoiRGF0YSBTY2llbmNlIiwiZmVlcyI6MTAwMDAsInN0dWRlbnROYW1lIjoiU3dhcGFuZGVlcCIsImluc3RpdHV0ZU5hbWUiOiJVbmFjYWRlbXkifQ=="
              );
            }}
          >
            Apply Now
          </button> */}
          </p>
          {/* </div> */}
        </div>
        <div className="login-background-mobile">
          {/* <div className="login-background-2"> */}

          <img
            src={FeeMonk_HighRes_Logo}
            className="feemonk-image-mobile m-auto"
            
            alt="website logo"
          />
          {/* <button
            className="applybtn1-mobile"
            onClick={() => {
              navigate(
                "/eyJtb2JpbGVOdW1iZXIiOiI4NzYzMDM5MzgwIiwiY291cnNlIjoiRGF0YSBTY2llbmNlIiwiZmVlcyI6MTAwMDAsInN0dWRlbnROYW1lIjoiU3dhcGFuZGVlcCIsImluc3RpdHV0ZU5hbWUiOiJVbmFjYWRlbXkifQ=="
              );
            }}
          >
            Apply Now
          </button> */}
          {/* </div> */}
        </div>
        <form className="form-container">
          <p className="started">To get started</p>
          <h3 className="login">Log In</h3>

          <div className="input-container">
            <p
              className="input-p"
              htmlFor="email"
              style={{
                fontFamily: "Inter-Medium",
                color: "#667085",
                marginLeft: "20%",
                lineHeight: "0.1em",
              }}
            >
              Enter Registered Mobile
            </p>
            <input
              type="text"
              id="outlined-basic"
              placeholder="Enter 10 digit Mobile Number"
              className="email-input-filed"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
            

            {otpSent ? (
              <small
                className="otp-resend"
                onClick={timer ? () => null : (event) => handleResendOtp(event)}
              >
                {timer ? `Didn't receive OTP? Wait for ${timer} and hit ` : ""}
                <strong
                  style={{
                    fontSize: "14px",
                    color: timer ? "grey" : "blue",
                    cursor: timer ? "not-allowed" : "pointer",
                    
                  }}
                >
                  <u>Resend otp</u>
                </strong>
              </small>
            ) : null}
            {mobileError && <div className="error">{mobileError}</div>}
          </div>



          {!otpSent ? (
            <div style={{ marginLeft: "22%", marginTop: "5%" }}>
              {/* <form>
                <Captcha onChange={(status) => setCaptchaSuccess(status)} />
              </form> */}
            </div>
          ) : null}

          {/* {!otpSent ? <div style={{marginLeft:'20%',marginTop:'5%'}}>
      <ReCAPTCHA
    sitekey="6Lfoex4qAAAAALpL18X_MDDAilmf5bNTOvJM9wIc"
    onChange={onChange}
  />
      </div>:null} */}
      {/* hi*/}
          {otpSent ? (
            <div className="input-container">
              <p
                className="input-p"
                htmlFor="password"
                style={{
                  fontFamily: "Inter-Medium",
                  color: "#667085",
                  marginLeft: "20%",
                  lineHeight: "0.1em",
                  marginTop: "5%",
                }}
              >
                OTP
              </p>
              <input
                type="text"
                id="password"
                className="password-input-filed"
                value={enteredOtp}
                onChange={(e) => setEnteredOtp(e.target.value)}
              />
              {/* {otpError && <div style={{ color: 'red' }}>{otpError}</div>} */}
            </div>
          ) : null}

          
          <div className="buttons-container1">
            {/* onClick={()=>navigate('/forgotPassword')} */}
            {/* <p  style={{color:'#D32028',textAlign:'right', cursor : 'pointer'}}><span style={{color:'#D32028',textAlign:'right', cursor : 'pointer'}} onClick={()=>navigate('/forgotPassword')}>Forgot Password ?</span></p> */}
            {!otpSent ? (
              <button
                type="submit"
                className="login-button"
                onClick={(e) => submitForm(e)}
                // disabled={!captchaSuccess}
                disabled={!(mobileNumber?.length === 10)}
                // style={{
                //   backgroundColor: !captchaSuccess ? "gray" : "#d32028",
                // }}

                style={{
                     backgroundColor: (mobileNumber?.length === 10) ?  "#d32028" :"gray",
                  }}
                
              >
                Get OTP
              </button>
            ) : (
              <button
                type="submit"
                className="login-button"
                onClick={(e) => submitOtp(e)}
              >
                Submit
              </button>
            )}

            {/* <div style ={{display:'flex', flexDirection : 'row', alignContent: 'center'}}><p style={{marginTop : '20px' , marginRight : '10px'}}>Don't have an account? </p><SignUpForm /> </div> */}
          </div>
          {/* <div className='or'>(or)</div>
        <div className="buttons-container1">
          <button  type="submit" className="login-button1" onClick={(event)=>event.preventDefault()}><img src={whatsapp} style={{height:'28px',width:'28px',marginRight:'10px'}}/>Login with WhatsApp</button>
        </div> */}
        </form>


        

        <form className="form-container-mobile">
          <p className="started-mobile">To get started</p>
          <p className="login-mobile">Log In</p>

          <div className = "login-mobile-inner-container">

          <div className="input-container-mobile">
            <label className="input-label-mobile" htmlFor="email">
              <span className= "enter-mobile-number-label" style={{ fontFamily: "Inter-Medium", color: "#667085"}}>
                Enter Registered Mobile
              </span>
            </label>
            <input type="text"
              id="outlined-basic"
              placeholder="Enter 10 digit Mobile Number"
              className="email-input-filed-mobile"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}

            />

            
           

            <br/>

            {/* otp didn't recieved message begin */}
            {/* {otpSent ? (
              <small
                className="otp-resend-mobile"
                onClick={
                  !timer
                    ? () => console.log("wait few seconds")
                    : () => handleResendOtp()
                }
              >
                {`Didn't receive OTP ? Wait for ${timer}s and hit{" "}`}
                <strong
                  style={{
                    fontSize: "14px",
                    color: !timer ? "grey" : "blue",
                    cursor: timer ? "pointer" : "",
                  }}
                >
                  <u>Resend</u>
                </strong>
              </small>
            ) : null} */}

{otpSent ? (
              <small
                className="otp-resend-mobile"
                onClick={timer ? () => null : (event) => handleResendOtp(event)}
              >
                {timer ? <p>{`Didn't receive OTP? Wait for ${timer} and hit `}</p> : ""}
                <strong
                  style={{
                    fontSize: "14px",
                    color: timer ? "grey" : "blue",
                    cursor: timer ? "not-allowed" : "pointer",
                  }}
                >
                  <u>Resend otp</u>
                </strong>
              </small>
            ) : null}
            {mobileError && <div className="error-mobile">{mobileError}</div>} 

            {/* otp didn't recieved message end */}
          </div>


          {!otpSent ? (
            <div>
              {/* <form >
                <Captcha  onChange={(status) => setCaptchaSuccess(status)} />
              </form> */}
            </div>
          ) : null}
          {/* {!otpSent ? <div style={{marginLeft:'10%',marginTop:'5%'}}>
      <ReCAPTCHA
    sitekey="6Lfoex4qAAAAALpL18X_MDDAilmf5bNTOvJM9wIc"
    onChange={onChange}
  />
      </div>:null} */}
      
          {otpSent ? (
            <div className="input-container-mobiles d-flex flex-column mt-4">
              
              <span className ="mb-1 ml-1" style={{ fontFamily: "Inter-Medium",color: "#667085",fontSize:"12px" }}>
                  OTP
                </span>
              {/* <label  htmlFor="password">
                
                <span style={{ fontFamily: "Inter-Medium",color: "#667085",fontSize:"12px" }}>
                  OTP
                </span>
              </label> */}
              <input
                type="text"
                id="password"
                placeholder="Enter Otp"
                className="password-input-filed-mobile"
                value={enteredOtp}
                onChange={(e) => setEnteredOtp(e.target.value)}
              />
              {/* {otpError && <div style={{ color: 'red' }}>{otpError}</div>} */}


              
            </div>
          ) : null}

</div>
    


          <div className="buttons-container1-mobile">
            {/* onClick={()=>navigate('/forgotPassword')} */}
            {/* <p  style={{color:'#D32028',textAlign:'right', cursor : 'pointer'}}><span style={{color:'#D32028',textAlign:'right', cursor : 'pointer'}} onClick={()=>navigate('/forgotPassword')}>Forgot Password ?</span></p> */}
            {!otpSent ? (
              <button
                type="submit"
                className="login-button-mobile"
                onClick={(e) => submitForm(e)}
                //disabled={!captchaSuccess}
                disabled={!(mobileNumber?.length === 10)}
                // style={{
                //   backgroundColor: !captchaSuccess ? "gray" : "#d32028", width:"70px",fontSize:"12px",
                // }}

                style={{
                  backgroundColor: (mobileNumber?.length === 10) ?  "#d32028" :"gray",
               }}
              >
                Get OTP
              </button>
            ) : (
              <button
                type="submit"
                className="login-button-mobile"
                onClick={(e) => submitOtp(e)}
              >
                Submit
              </button>
            )}
            {/* <div style ={{display:'flex', flexDirection : 'row', alignContent: 'center'}}><p style={{marginTop : '20px' , marginRight : '10px'}}>Don't have an account? </p><SignUpForm /> </div> */}
          </div>
          {/* <div className='or-mobile'>(or)</div>
            <div className="buttons-container1-mobile">
            <button  type="submit" className="login-button1-mobile" onClick={(event)=>event.preventDefault()}><img src={whatsapp} style={{height:'28px',width:'28px',marginRight:'10px'}}/>Login with WhatsApp</button>
            </div> */}
        </form>
      </div>
      <div></div>
    </>
  );
}




export default Index