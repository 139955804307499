import React, { useEffect, useState } from "react";
import axios from 'axios'
import Label from "../../components/atoms/Label";
import InputText from "../../components/atoms/InputText";
import styles from "./styles.module.css";
import Button from "../../components/atoms/Button";
import Checkbox from "../../components/atoms/Checkbox";
import RightArrow from "../../images/chevron_right.png";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import close from '../../images/icons/close.png'

import Navbar from "../../components/molecules/Navbar";
import { useNavigate } from "react-router-dom";
import ErrorDialog from "../../components/molecules/ErrorDialog";
import {  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';



enum DialogOpen {
  BASIC = "BASIC",
  RESIDENCE = "RESIDENCE",
  EMPLOYEMENT = "EMPLOYEMENT",
  KYC = "KYC",
}

interface FormValue {
  inst: string;
  course: string;
  coursefee: string;
  fname: string;
  lname: string;
  dob: string;
  phone: string;
  cophone: string;
  gender: string;
  Email1: string;
  Address: string;
  City: string;
  State: string;
  Zip: string;
  emp: string;
  sal: string;
  typofB: string;
  Income: string;
  adhaarnum: string;
  adharf: string;
  adharb: string;
  pan: string;
  pannum: string;
  check: string;
  latitude:string;
  longitude:string;
  isSubmit: string;
  errorMsg: string;
}

function Form() {


  const [modal, setModal] = useState(false);
  const [location, setLocation] = useState({
    latitude:0,
    longitude:0});
  const toggle = () => ( setModal(!modal));

  function handleLocationClick() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success,err);
    } else {
      console.log("Geolocation not supported");
    }
  }

  function success(position:any) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    
    setLocation({
        latitude,
        longitude
    });
    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);

    
  }

  function err() {
    console.log("Unable to retrieve your location");
  }

  const [link,setLink]=React.useState("");
  // ( window as any).getBureauSession('708587bad0904485abe1127847dd62cd','0bf296d0-eedf-450e-96cc','SHARAT','','KONATHAM','8499802183').then((res : any)=>{
  //   console.log(res)
  // setLink(res)
  // }
  
  const [instituteList,setInstituteList]=useState([])  
  const [startingPhrase,setStarterPhrase]=useState("Type Institute Name")


  const getInstituteInfo=(text:any)=>{
  const instituteInfoUrl=`${process.env.REACT_APP_BASE_URL}/institute/info/name?name=${text}`;
  value.inst=text
  if(text.length>=4)
  {
    axios.get(instituteInfoUrl,{
      headers:{
        Authorization:`Bearer ${authToken}`
      }
    }).then((res:any)=>{
      if(res.data.data.length===0)
      {
        setInstituteList([])
        setStarterPhrase("No institute Found")
      }
      else
      setInstituteList(res.data.data)

    })
  }
  else{
    setInstituteList([])
    setStarterPhrase("Type Institute Name")
  }
}
  const [selectedInstitute,setSelectedstyleInstituteName]=useState('')
const handleSearchInstituteDetails=(data :any)=>{
  
  // setCurrentInstituteId(data.id)
  (document.getElementById('styleinstituteNameField') as any).value=data
  value.inst=data
   setInstituteList([]);
   setSelectedstyleInstituteName(data)
   
  
}


  const [radio, setRadio] = useState("EMPLOYED");
  const [dialogOpen, setDialogOpen] = useState<DialogOpen>(DialogOpen.BASIC);
  const [value, setValue] = useState<FormValue>({
    inst: "",
    course: "",
    coursefee: "",
    fname: "",
    lname: "",
    dob: "",
    phone: "",
    cophone: "",
    gender: "",
    Email1: "",
    Address: "",
    City: "",
    State: "",
    Zip: "",
    emp: "",
    sal: "",
    typofB: "",
    Income: "",
    adhaarnum: "",
    adharf: "",
    adharb: "",
    pan: "",
    pannum: "",
    check: "",
    latitude:"",
    longitude:"",
    isSubmit: "",
    errorMsg: "",
  });

  const navigate = useNavigate();

  // const changeHandler = (name) => (e: React.ChangeEvent<HTMLInputElement>) => {

  // };
  const [authToken, setAuthToken] = useSessionStorage("feemonk_data", "");
  const [error, setError] = useState("");
  const [isEmailError, showNoValidEmail] = useState(false);


  const handleChange = (key: string, val: string) => {
    setValue({ ...value, [key]: val });
  };

  const submitBasic = (value: FormValue) => {
    var myHeaders = new Headers();

    myHeaders.append("Authorization", `Bearer ${authToken && authToken.value}`);

    var requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify({
        mobile: value.phone,
        fullName: value.fname + " " + value.lname,
        dateOfBirth: value.dob,
        isGuarantor: false,
        isParentOfMinor: false,
        isCoapplicant: false,
        coApplicantRelation: "self",
        guarantorRelation: "self",
        relatedTo: "",
        instituteName: value.inst,
        courseName: value.course,
        courseFees: value.coursefee,
        email: value.Email1,
      }),
    };
    fetch(`${process.env.REACT_APP_BASE_URL}/users/basic-info/create`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  const submitEmployee = (value: FormValue) => {
    var myHeaders = new Headers();

    myHeaders.append("Authorization", `Bearer ${authToken && authToken.value}`);

    var requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(
        radio === "EMPLOYED"
          ? {
              employmentType: "salaried",
              employerName: value.emp,
              salary: value.sal,
            }
          : {
              employmentType: "self-employed",
              incomePerMonth: value.Income,
              typeOfBusiness: value.typofB,
            }
      ),
    };
    fetch(`${process.env.REACT_APP_BASE_URL}/users/employment-details/create`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  const submitResidence = (value: FormValue) => {
    var myHeaders = new Headers();

    myHeaders.append("Authorization", `Bearer ${authToken && authToken.value}`);

    var requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify({
        email: value.Email1,
        currentAddress: value.Address,
        currentCity: value.City,
        currentState: value.State,
        currentPincode: value.Zip,
      }),
    };
    fetch(`${process.env.REACT_APP_BASE_URL}/users/profile-details/create`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  const handleSubmit = async (value: FormValue) => {
    
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

      const validateEmail = () => {
        if(value.Email1 && value.Email1.match(isValidEmail)){
          showNoValidEmail(false);
          
        }else{
          showNoValidEmail(true);
        }
      }
      validateEmail()
      handleLocationClick()
    if (!value.fname) {
      setError("Please enter first name");
      return;
    }
    if (!value.phone) {
      setError("Please enter phone number");
      return;
    }
    if (!value.dob || value.dob === "Invalid date") {
      setError("Please enter date of birth");
      return;
    }
    if (!value.gender) {
      setError("Please enmter gender");
      return;
    }
    if (!isEmailError) {
      setError("Please enter email");
      return;
    }
    if (!value.inst) {
      setError("Please enter institute name");
      return;
    }
    if (!value.course) {
      setError("Please enter course name");
      return;
    }
    if (!value.coursefee) {
      setError("Please enter course fee");
      return;
    }

    if (!value.Address) {
      setError("Please enter address");
      return;
    }
    if (!value.City) {
      setError("Please enter city");
      return;
    }
    if (!value.State) {
      setError("Please enter state");
      return;
    }
    if (!value.Zip) {
      setError("Please enter zip code");
      return;
    }

    if (radio === "EMPLOYED") {
      if (!value.emp) {
        setError("Please enter employer name");
        return;
      }
      if (!value.sal) {
        setError("Please enter salary");
        return;
      }
    }

    if (radio === "SELF_EMPLOYED") {
      if (!value.Income) {
        setError("Please enter income per month");
        return;
      }
      if (!value.typofB) {
        setError("Please enter type of business");
        return;
      }
    }

    if (!value.check) {
      setError("Please accept the terms and conditions");
      return;
    }
    
    await submitBasic(value);
    await submitEmployee(value);
    await submitResidence(value);

    // navigate("/thank-you");
  };

  useEffect(() => {
    console.log(authToken);

    if (authToken.mob) {
      setValue((val: FormValue) => ({
        ...val,
        phone: authToken.mob,
      }));
    }

    var myHeaders = new Headers();

    myHeaders.append("Authorization", `Bearer ${authToken && authToken.value}`);

    var requestOptions: RequestInit = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    // fetch(`${process.env.REACT_APP_BASE_URL}/institute/info?name=Medvarsit`, requestOptions);

    fetch(`${process.env.REACT_APP_BASE_URL}/summary`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);

        if (result?.data) {
          setValue((val: FormValue) => ({
            ...val,

            fname: result?.data?.firstName,
            lname: result?.data?.lastName,
            dob: result?.data?.dateOfBirth,
            phone: result?.data?.mobile,
            gender: result?.data?.gender,
            Address: result?.data?.currentAddress,
            City: result?.data?.currentCity,
            State: result?.data?.currentState,
            Zip: result?.data?.currentPincode,
            Email1: result?.data?.email,
            inst: result?.data?.instituteName,
            course: result?.data?.courseName,
            coursefee: result?.data?.courseFees,
            Employement: result?.data?.employmentType,
            emp: result?.data?.employerName,
            sal: result?.data?.salary,
            Income: result?.data?.incomePerMonth,
            typofB: result?.data?.typeOfBusiness,
            pannum: result?.data?.panId,
            adhaarnum: result?.data?.aadhaarId,
          }));
        }
      })
      .catch((error) => console.log("error", error));
  }, [authToken]);

  const uploadFile1 = (event: any) => {
    
    let fileList = event.target.files;
    if (fileList) {
      const formdoc = new FormData();
      formdoc.append("type", "adhar_front");
      formdoc.append("file", fileList[0]);

      var myHeaders = new Headers();

      myHeaders.append(
        "Authorization",
        `Bearer ${authToken && authToken.value}`
      );

      var requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: formdoc,
      };

      fetch(`${process.env.REACT_APP_BASE_URL}/file/upload`, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          console.log(data?.data?.s3Url);
          setValue((val: FormValue) => ({
            ...val,
            adharf: data?.data?.s3Url,
          }));
        })
        .catch((err) => console.log(err));
    }
  };

  const uploadFile2 = (event: any) => {
    console.log("this is uploaded2 event",event)
    let fileList = event.target.files;
    console.log("this is files uploaded",fileList)
    if (fileList) {
      const formdoc = new FormData();
      formdoc.append("type", "adhar_back");
      formdoc.append("file", fileList[0]);

      var myHeaders = new Headers();

      myHeaders.append(
        "Authorization",
        `Bearer ${authToken && authToken.value}`
      );

      var requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: formdoc,
      };

      fetch(`${process.env.REACT_APP_BASE_URL}/file/upload`, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          console.log("this is data",data?.data?.s3Url);
          setValue((val: FormValue) => ({
            ...val,
            adharb: data?.data?.s3Url,
          }));
        })
        .catch((err) => console.log(err));
    }
  };

  const uploadFile3 = (event: any) => {
    let fileList = event.target.files;
    if (fileList) {
      const formdoc = new FormData();
      formdoc.append("type", "pan");
      formdoc.append("file", fileList[0]);

      var myHeaders = new Headers();

      myHeaders.append(
        "Authorization",
        `Bearer ${authToken && authToken.value}`
      );

      var requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: formdoc,
      };

      fetch(`${process.env.REACT_APP_BASE_URL}/file/upload`, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          console.log(data?.data?.s3Url);
          setValue((val: FormValue) => ({
            ...val,
            pan: data?.data?.s3Url,
          }));
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div>
       <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>Consent:
         <img src={close}style={{width:'15px',height:'15px',marginTop:'10px',cursor:'pointer',position:'absolute',right:'2rem'}} onClick={toggle}/></ModalHeader>
        <ModalBody>
        <iframe width="400" height="600" src={link} onLoad={( window as any).startBureauSession} title="bureau">
        </iframe>
        </ModalBody>
        {/* <ModalFooter>
          <button color="primary" onClick={()=>toggle()}>
            Do Something
          </button>{' '}
          <button color="secondary" onClick={()=>toggle()}>
            Cancel
          </button>
        </ModalFooter> */}
      
      </Modal>  
      <div className={styles.main}>
        <Navbar />
        <div className={styles.container}>
          <div className={styles.dialogHeader}>
            <span></span>
            <h2>Basic</h2>
            <span
              onClick={() => {
                setDialogOpen(DialogOpen.BASIC);
              }}
            >
              <img
                src={RightArrow}
                alt=""
                style={{
                  transform:
                    dialogOpen === DialogOpen.BASIC
                      ? "rotate(90deg)"
                      : "rotate(270deg)",
                }}
              ></img>
            </span>
          </div>
          {error.trim() && (
            <ErrorDialog error={error} onClose={() => setError(" ")} />
          )}
          {dialogOpen === DialogOpen.BASIC && (
            <>
              <div className={styles.inputField}>
                <Label text="Institute Name" />
                 <div className={styles.inputField}>
                <input
                  className={styles.instituteSearch}
                  id="styleinstituteNameField"
                  placeholder="Institute Name"
                  type="text"
                  onChange={(e:any)=>getInstituteInfo(e.target.value)}
                  defaultValue={selectedInstitute}
                  

                  
                />
                </div>
                <div style={{marginTop:'0.5em',position: 'absolute',zIndex:'999',backgroundColor:'#fff',boxShadow:'0px 0px 3px 0px rgb(0,0,0.4,0.7)',height:instituteList.length>0?'25%':'0px',overflowY: 'scroll',width:'50vw',color:"black",marginLeft:'2rem'}}>
                  {
                     instituteList.map((item : any)=>{
                      return(
                        <div onClick={()=>{handleSearchInstituteDetails(item.brandName)}} style={{cursor:'pointer',border:'1px solid #DbDbDB',}}>
                        <p  style={{fontWeight:'600',paddingTop:'0.5em',}}>{item.brandName}</p>
                        </div>
                      )
                      }
                      )

                  }
                  </div>
              </div>
              <div className={styles.inputField}>
                <Label text="Course Name" />
                <InputText
                  placeholder="Course Name"
                  type="text"
                  changeHandler={(e:any) => handleChange("course", e.target.value)}
                  value={value.course}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Course Fee" />
                <InputText
                  placeholder="Course Fee"
                  type="number"
                  changeHandler={(e:any) =>
                    handleChange("coursefee", e.target.value)
                  }
                  value={value.coursefee}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="First Name" />
                <InputText
                  placeholder="First Name"
                  type="text"
                  changeHandler={(e:any) => handleChange("fname", e.target.value)}
                  value={value.fname}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Last Name" />
                <InputText
                  placeholder="Last Name"
                  type="text"
                  changeHandler={(e:any) => handleChange("lname", e.target.value)}
                  value={value.lname}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Date of Birth" />
                <InputText
                  placeholder="Date of Birth"
                  type="date"
                  changeHandler={(e:any) => handleChange("dob", e.target.value)}
                  value={value.dob}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Gender" />
                <InputText
                  placeholder="Gender"
                  type="option"
                  changeHandler={(e:any) => handleChange("gender", e.target.value)}
                  value={value.gender}
                  options={[
                    {
                      placeholder: "Gender",
                      value: "",
                    },
                    {
                      placeholder: "Male",
                      value: "male",
                    },
                    {
                      placeholder: "Female",
                      value: "female",
                    },
                  ]}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Phone Number" />
                <InputText
                  placeholder="Phone Number"
                  type="tel"
                  disabled={!!value.phone}
                  changeHandler={(e:any) => handleChange("phone", e.target.value)}
                  value={value.phone}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Email address" />
                <InputText
                  placeholder="Email address"
                  type="email"
                  changeHandler={(e:any) => handleChange("Email1", e.target.value)}
                  value={value.Email1}
                />
              </div>
            </>
          )}
        </div>
        <div className={styles.container}>
          <div className={styles.dialogHeader}>
            <span></span>
            <h2>Residence</h2>
            <span
              onClick={() => {
                setDialogOpen(DialogOpen.RESIDENCE);
              }}
            >
              <img
                src={RightArrow}
                alt=""
                style={{
                  transform:
                    dialogOpen === DialogOpen.RESIDENCE
                      ? "rotate(90deg)"
                      : "rotate(270deg)",
                }}
              ></img>
            </span>
          </div>
          {dialogOpen === DialogOpen.RESIDENCE && (
            <>
              <div className={styles.inputField}>
                <Label text="Address" />
                <InputText
                  placeholder="Address"
                  type="text"
                  changeHandler={(e:any) => handleChange("Address", e.target.value)}
                  value={value.Address}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="City" />
                <InputText
                  placeholder="City"
                  type="text"
                  changeHandler={(e:any) => handleChange("City", e.target.value)}
                  value={value.City}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="State" />
                <InputText
                  placeholder="State"
                  type="text"
                  changeHandler={(e:any) => handleChange("State", e.target.value)}
                  value={value.State}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Zip" />
                <InputText
                  placeholder="Zip"
                  type="text"
                  changeHandler={(e:any) => handleChange("Zip", e.target.value)}
                  value={value.Zip}
                />
              </div>
            </>
          )}
        </div>
        <div className={styles.container}>
          <div className={styles.dialogHeader}>
            <span></span>
            <h2>Employement</h2>
            <span
              onClick={() => {
                setDialogOpen(DialogOpen.EMPLOYEMENT);
              }}
            >
              <img
                src={RightArrow}
                alt=""
                style={{
                  transform:
                    dialogOpen === DialogOpen.EMPLOYEMENT
                      ? "rotate(90deg)"
                      : "rotate(270deg)",
                }}
              ></img>
            </span>
          </div>
          {dialogOpen === DialogOpen.EMPLOYEMENT && (
            <>
              <div
                onChange={(event) =>
                  setRadio((event.target as HTMLInputElement).value)
                }
                defaultValue="EMPLOYED"
                className={styles.radio}
              >
                <label>
                  <input
                    type="radio"
                    value="EMPLOYED"
                    name="employment"
                    checked={radio === "EMPLOYED"}
                  />
                  Salaried
                </label>
                <label>
                  <input
                    type="radio"
                    value="SELF_EMPLOYED"
                    name="employment"
                    checked={radio === "SELF_EMPLOYED"}
                  />
                  Self Employed
                </label>
                <label>
                  <input
                    type="radio"
                    value="UNEMPLOYED"
                    name="employment"
                    checked={radio === "UNEMPLOYED"}
                  />
                  Unemployed
                </label>
              </div>
              {radio === "EMPLOYED" && (
                <>
                  <div className={styles.inputField}>
                    <Label text="Employer Name" />
                    <InputText
                      placeholder="Employer Name"
                      type="text"
                      changeHandler={(e:any) => handleChange("emp", e.target.value)}
                      value={value.emp}
                    />
                  </div>
                  <div className={styles.inputField}>
                    <Label text="Salary" />
                    <InputText
                      placeholder="Salary"
                      type="number"
                      changeHandler={(e:any) => handleChange("sal", e.target.value)}
                      value={value.sal}
                    />
                  </div>
                </>
              )}
              {radio === "SELF_EMPLOYED" && (
                <>
                  <div className={styles.inputField}>
                    <Label text="Type of Business" />
                    <InputText
                      placeholder="Type of Business"
                      type="text"
                      changeHandler={(e:any) =>
                        handleChange("typofB", e.target.value)
                      }
                      value={value.typofB}
                    />
                  </div>
                  <div className={styles.inputField}>
                    <Label text="Income per Month" />
                    <InputText
                      placeholder="Income per Month"
                      type="number"
                      changeHandler={(e:any) =>
                        handleChange("Income", e.target.value)
                      }
                      value={value.Income}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div className={styles.container}>
          <div className={styles.dialogHeader}>
            <span></span>
            <h2>KYC</h2>
            <span
              onClick={() => {
                setDialogOpen(DialogOpen.KYC);
              }}
            >
              <img
                src={RightArrow}
                alt=""
                style={{
                  transform:
                    dialogOpen === DialogOpen.KYC
                      ? "rotate(90deg)"
                      : "rotate(270deg)",
                }}
              ></img>
            </span>
          </div>
          {dialogOpen === DialogOpen.KYC && (
            <>
              <div className={styles.inputField}></div>
              <div className={styles.inputField}>
                <Label text="Aadhaar Front" />
                <div className={styles.inputField}>
                  <input
                    type="file"
                    name="aadharFront"
                    onChange={(e: any) => {
                      uploadFile1(e);
                    }}
                  />
                  <img
                    src={value.adharf || ""}
                    alt=""
                    style={{
                      height: 300,
                    }}
                  />
                </div>
                <Label text="Aadhaar Back" />
                <div className={styles.inputField}>
                  <input
                    type="file"
                    name="aadharBack"
                    onChange={(e: any) => {
                      console.log("File change event triggered", e);
                      uploadFile2(e);
                    }}
                  />
                  <img
                    src={value.adharb || ""}
                    alt=""
                    style={{
                      height: 300,
                    }}
                  />
                </div>
                <Label text="Aadhaar Number" />
                <InputText
                  placeholder="Aadhaar Number"
                  type="text"
                  changeHandler={(e:any) =>
                    handleChange("adhaarnum", e.target.value)
                  }
                  value={value.adhaarnum}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Pan Card" />
                <div className={styles.inputField}>
                  <input
                    type="file"
                    name="panCard"
                    onChange={(e: any) => {
                      uploadFile3(e);
                    }}
                  />
                  <img
                    src={value.pan || ""}
                    alt=""
                    style={{
                      height: 300,
                    }}
                  />
                </div>
                <Label text="Pan Number" />
                <InputText
                  placeholder="Pan Number"
                  type="text"
                  changeHandler={(e:any) => handleChange("pannum", e.target.value)}
                  value={value.pannum}
                />
              </div>
            </>
          )}
        </div>
        <div
          className={styles.container}
          style={{
            padding: "1rem",
          }}
        >
          <Checkbox
            value={value.check}
            onChange={(e: any) => handleChange("check", e.target.checked)}
            label="By checking this box I agree to the Terms & Conditions of Feemonk and also provide my consent to Feemonk to access my Credit Profile from authorized Credit information Bureas"
          />
          <Button
            onPress={() => {
              handleSubmit(value);
            }}
            text="Submit"
          />
        </div>
      </div>
    </div>
  );
}

export default Form;
