import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSessionStorage } from '../hooks/useSessionStorage';


interface PrivateRouteProps {
  children: React.ReactNode;
}

function PrivateRoute({ children }: PrivateRouteProps) {
  const navigate = useNavigate();
  const [authToken] = useSessionStorage("feemonk_data", "");

  useEffect(() => {
    if (!authToken) {
      navigate("/");
    }

    fetch(`${process.env.REACT_APP_BASE_URL}/login/auth`, {
      headers: {
        Authorization: `Bearer ${authToken.value}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.data) {
          navigate("/");
        } else {
        }
      });
  }, [authToken.value, navigate, authToken]);

  return <>{children}</>;
}

export default PrivateRoute;
