import React from "react";
import styles from "./index.module.css";
import Button from "../../atoms/Button";

function Navbar() {
  return (
    <div className={styles.container}>
      <img
        src={"/feemonklogo.png"}
        style={{ width: "7rem" }}
        alt="feemonk_logo"
      />
      <Button
        onPress={() => {
          sessionStorage.setItem(
            "feemonk_data",
            JSON.stringify({ value: "", mob: "" })
          );
          sessionStorage.setItem("ocr_data", JSON.stringify({ id: "" }));
          sessionStorage.clear()
          window.location.href = "/login";
        }}
        text={"Logout"}
      />
    </div>
  );
}

export default Navbar;
