import React, { useState } from "react";
import styles from "./styles.module.css";
import DocumentCard from "../../components/molecules/DocumentCard";
import Dropzone from "react-dropzone";
import Button from "../../components/atoms/Button";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import Loader from './loader';
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/molecules/Navbar";

function Upload() {
  const [aadhaarA, setAadhaarA] = useState<File[]>([]);
  const [aadhaarB, setAadhaarB] = useState<File[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [pan, setPan] = useState<File[]>([]);

  const navigate = useNavigate();

  const [authToken, setAuthToken] = useSessionStorage("feemonk_data", "");
  const [ocrData, setOcrData] = useSessionStorage("ocr_data", "");

  const submit = () => {
    const formdoc = new FormData();

    if (aadhaarA.length) {
      formdoc.append("aadhaarFront", aadhaarA[0]);
    }
    if (aadhaarB.length) {
      formdoc.append("aadhaarBack", aadhaarB[0]);
    }
    if (pan.length) {
      formdoc.append("pan", pan[0]);
    }

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${authToken && authToken.value}`);

    const requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: formdoc,
    };

    setLoading(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/ocr/read`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setOcrData(data.data);
        sessionStorage.setItem(
          "ocr_data",
          JSON.stringify({
            id: data?.data?.id,
          })
        );
        navigate("/admin-form");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const removeFile = (setter: React.Dispatch<React.SetStateAction<File[]>>) => {
    setter([]);
  };

  return (
    <div>
      <div className={styles.main}>
        <Navbar />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <h1>Upload</h1>
            <div className={styles.container}>
              <DocumentCard>
                <Dropzone
                  onDrop={(acceptedFiles) => setAadhaarA(acceptedFiles)}
                  accept={{ "image/jpeg": [], "image/png": [] }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className={styles.uploadBox}>
                      <div {...getRootProps()} className={styles.dropzone}>
                        <input {...getInputProps()} />
                        <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                          Drag and Drop or Upload Aadhaar Front Image or Complete Aadhaar here
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
                {aadhaarA[0] && (
                  <div className={styles.imagePreviewContainer}>
                    <div className={styles.imagePreview}>
                      <img
                        src={URL.createObjectURL(aadhaarA[0])}
                        alt="Aadhaar Front"
                        style={{ height: 300, maxWidth: "60%", width: "auto" }}
                      />
                    </div>
                    <div style={{display:'flex',justifyContent:'center'}}>
                    <Button text={"X"} onPress={() => removeFile(setAadhaarA)} />
                    </div>
                    
                  </div>
                )}
              </DocumentCard>

              <DocumentCard>
                <Dropzone
                  onDrop={(acceptedFiles) => setAadhaarB(acceptedFiles)}
                  accept={{ "image/jpeg": [], "image/png": [] }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className={styles.uploadBox}>
                      <div {...getRootProps()} className={styles.dropzone}>
                        <input {...getInputProps()} />
                        <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                          Drag and Drop or Upload Aadhaar Back Image here
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
                {aadhaarB[0] && (
                  <div className={styles.imagePreviewContainer}>
                    <div className={styles.imagePreview}>
                      <img
                        src={URL.createObjectURL(aadhaarB[0])}
                        alt="Aadhaar Back"
                        style={{ height: 300, maxWidth: "60%", width: "auto" }}
                      />
                    </div>
                    <div style={{display:'flex',justifyContent:'center'}}>
                    <Button text={"X"} onPress={() => removeFile(setAadhaarB)} />
                    </div>
                   
                  </div>
                )}
              </DocumentCard>

              <DocumentCard>
                <Dropzone
                  onDrop={(acceptedFiles) => setPan(acceptedFiles)}
                  accept={{ "image/jpeg": [], "image/png": [] }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className={styles.uploadBox}>
                      <div {...getRootProps()} className={styles.dropzone}>
                        <input {...getInputProps()} />
                        <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                          Drag and Drop or Upload PAN Image here
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
                {pan[0] && (
                  <div className={styles.imagePreviewContainer}>
                    <div className={styles.imagePreview}>
                      <img
                        src={URL.createObjectURL(pan[0])}
                        alt="Pan"
                        style={{ height: 300, maxWidth: "60%", width: "auto" }}
                      />
                    </div>
                    <div style={{display:'flex',justifyContent:'center'}}>
                    <Button text={"X"} onPress={() => removeFile(setPan)} />
                    </div>
                  
                  </div>
                )}
              </DocumentCard>
            </div>
            <Button text={"Submit"} onPress={submit} />
          </>
        )}
      </div>
    </div>
  );
}

export default Upload;
