import React from "react";
import styles from "./styles.module.css";
import HoldOn from "../../images/hold-on.svg";
import Button from "../../components/atoms/Button";

function ThankYou() {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        {/* <h1>Mandate</h1> */}
        <img src="/feemonklogo.png" alt="" className={styles.image} />
        <img src={HoldOn} alt="" className={styles.heroImage} />
        <div className={styles.holdOnTextContainer}>
          <p className={styles.holdOnHeaderText}>Thank you!</p>
          <p>Your application is under review. We will update soon</p>
        </div>

        <div>
          <p className={styles.holdOnHelperText}>
            If you have a problem, please contact us:
          </p>
          <p className={styles.holdOnHelperTextSmall}>hello@feemonk.com</p>
        </div>
        <div>
          <Button
            onPress={() => {
              sessionStorage.setItem(
                "feemonk_data",
                JSON.stringify({ value: "", mob: "" })
              );
              sessionStorage.setItem("ocr_data", JSON.stringify({ id: "" }));
              window.location.href = "/login";
            }}
            text={"Logout"}
          />
        </div>
      </div>
    </div>
  );
}

export default ThankYou;
