import axios from 'axios'

export const userService = {
    loginApi,
    submitOtpApi,  
  };


async function loginApi(url, body) {
    const response =await  axios.post(process.env.REACT_APP_BASE_URL + url, body)
      .then((res)=>{
        return res.status
        
      })
      .catch(e => {
        console.log(e)
        alert("Error While Sending OTP!!!")
      })
    return response;
}




async function submitOtpApi(url,body) {

    const response=await axios.post(process.env.REACT_APP_BASE_URL + url,body)
    .then((res)=>{
    if(res.status === 200){
       
       console.log("res",res)
       sessionStorage.setItem("token",res?.data?.data)
        return res
    }
    else
    {
      alert("Invalid OTP");
      return false;
    }

  })
    .catch((err)=>{
      alert(err?.response?.data?.message)
      return err;
    })

    return response;
    
  }